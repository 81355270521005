import React, {useState} from "react"
import * as styles from "../styles/rgpd.module.scss"
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const RgpdOptOut = ({lang}) => {

  lang = lang.slice(0,2)

  const revokeCookies = () => {
    document.cookie.split(";").forEach((c) => {
      // document.cookie = c
      //   .replace(/^ +/, "")
      //   .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      cookies.remove(c, {path: "/" } )
      cookies.remove(c, {path: "/", domain: ".thesunproject.fr" } )
    });

    cookies.set('opt-out', "true", {maxAge: (24 * 365 * 3600), path: "/" } )
    cookies.set('banner', "false", {maxAge: (24 * 365 * 3600), path: "/" })
    window[`ga-disable-G-HDS29BJKZZ`] = true
  }
  
  const[banner, setBanner] = useState(() => {
    return cookies.get('_ga') ? true
    : false
  })
  
  return (
    <>
      {banner && 
        <>
          <div className={styles.optOut}>
            {lang === "fr" &&
              <>Si vous souhaitez revoquer les cookies <span onClick={revokeCookies}> cliquez ici</span>.</>
            }
             {lang === "en" &&
              <>To revoke cookies <span onClick={revokeCookies}> click here</span>.</>
            }
          </div>
        </>
      }
    </>
  )
}

export default RgpdOptOut
