import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react";
import { components } from "../slices";
import Layout from "../components/Layout";
import Content from "../components/Content";
import Contact from "../components/Contact";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ParallaxProvider } from "react-scroll-parallax";
import Doodle from "../components/Doodle";
import BackgroundSlides from "../components/BackgroundSlides";
import "../styles/page.scss";
import RgpdOptOut from "../components/RgpdOptOut";
import favicon from "../assets/favicon.png";

const Page = ({ location, data }) => {
  const page = data.prismicPage;
  const lang = page.lang.slice(0, 2);

  return (
    <ParallaxProvider>
      <Layout page={page} type="page">
        <main>
          <BackgroundSlides
            lang={lang}
            location={location}
            color={page.data.page_color}
          />
          <Content color={page.data.page_color}>
            <div
              className={`page-header ${
                page.data.main_image.localFile ? "absolute" : "fixed"
              }`}
            >
              <h1>
                {page.data.headline.text
                  ? page.data.headline.text
                  : page.data.title.text}
              </h1>
            </div>
            {page.data.main_image.localFile && (
              <div className="main-image-container">
                <GatsbyImage
                  image={getImage(page.data.main_image.localFile)}
                  objectFit="COVER"
                  objectPosition={page.data.main_image_position}
                  className={"main-image"}
                />
              </div>
            )}

            {page.data.manifesto.richText.length > 0 && (
              <div className="page-manifesto">
                <Doodle data={page.data.manifesto.richText} size={"light"} />
              </div>
            )}
            <SliceZone slices={page.data.body} components={components} />

            {(page.uid === "mentions-legales" ||
              page.uid === "legal-notice") && <RgpdOptOut lang={page.lang} />}
          </Content>
          <Contact lang={page.lang} />
        </main>
      </Layout>
    </ParallaxProvider>
  );
};

export default Page;

export const Head = ({ data }) => (
  <>
    <title>{`${data.prismicPage.data.title.text.toLowerCase()} → the sun project`}</title>
    <meta
      name="description"
      content={data.prismicPage.data.seo_description.text}
    />
    <meta name="keywords" content={data.prismicPage.data.seo_keywords.text} />
    <meta name="author" content="the sun project" />

    <meta
      property="og:title"
      content={`${data.prismicPage.data.title.text.toLowerCase()} → the sun project`}
    />
    <meta property="og:site_name" content="the sun project" />
    <meta
      property="og:description"
      content={data.prismicPage.data.seo_description.text}
    />
    <meta
      property="og:image"
      content={data.prismicPage.data.main_image.localFile.url}
    />
    <meta property="og:locale" content={data.prismicPage.lang} />
    <meta property="og:type" content="website" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="the sun project" />
    <meta
      name="twitter:title"
      content={`${data.prismicPage.data.title.text.toLowerCase()} → the sun project`}
    />
    <meta
      name="twitter:description"
      content={data.prismicPage.data.seo_description.text}
    />
    <meta
      name="twitter:image"
      content={data.prismicPage.data.main_image.localFile.url}
    />

    <link rel="icon" type="image/png" href={favicon} />
  </>
);

export const pageQuery = graphql`
  query PageBySlug($uid: String, $lang: String) {
    prismicPage(id: { eq: $uid }, lang: { eq: $lang }) {
      lang
      uid
      type
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title {
          text
        }
        headline {
          text
        }
        seo_keywords {
          text
        }
        seo_description {
          text
        }
        manifesto {
          richText
        }
        main_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 3000
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            url
          }
        }
        main_image_position
        page_color
        body {
          ...PageDataBodySectionTitle
          ...PageDataBodyTextBox
          ...PageDataBodyListBox
          ...PageDataBodyImageBox
          ...PageDataBodySlideshow
          ...PageDataBodyEmbedBox
          ...PageDataBodyImageGrid
          ...PageDataBodyLogoBox
          ...PageDataBodyExperienceBox
          ...PageDataBodyTextImageBox
          ...PageDataBodyCreationSlideshow
          ...PageDataBodyEmbedMultiple
          ...PageDataBodyFaq
        }
      }
    }
  }
`;
